import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import { HeroSliderDataTypes } from './types';

const Item = styled.div`
  height: 92vh;
  @media (min-width: 768px) {
    height: 100vh;
  }
`;

const Container = styled(motion.div)``;

interface HeroSliderProps {
  data: HeroSliderDataTypes[];
}

export default function HeroSlider({ data }: HeroSliderProps) {
  const [windowWidth, setWindowWidth] = useState<number>(0);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  windowWidth === 0 && <>loading...</>;

  const renderNextArrow = (onClickHandler: any, hasNext: boolean, label: string) => (
    <button
      onClick={onClickHandler}
      className="text-white absolute right-5 bottom-5 z-50 outline-none focus:outline-none"
    >
      {hasNext && <FontAwesomeIcon icon={faAngleRight} size="2x" />}
    </button>
  );

  const renderPrevArrow = (onClickHandler: any, hasPrev: boolean, label: string) => (
    <button
      onClick={onClickHandler}
      className="text-white absolute left-5 bottom-5 z-50 outline-none focus:outline-none"
    >
      {hasPrev && <FontAwesomeIcon icon={faAngleLeft} size="2x" />}
    </button>
  );

  const items = data.map((item, idx) => {
    return (
      <Item key={idx} className="w-full">
        <img src={item.img} alt="image" className="transition-all object-cover h-full" />
      </Item>
    );
  });

  return (
    <Container className="overflow-visible overscroll-none relative hero">
      <Carousel
        swipeable={false}
        emulateTouch
        showThumbs={false}
        showStatus={false}
        showIndicators={window.innerWidth >= 980}
        showArrows={window.innerWidth <= 980}
        renderArrowNext={renderNextArrow}
        renderArrowPrev={renderPrevArrow}
      >
        {items}
      </Carousel>
    </Container>
  );
}
