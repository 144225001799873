import VIDEO1_IMG from '../../../assets/images/video-1.png';
import VIDEO2_IMG from '../../../assets/images/video-2.png';
import VIDEO3_IMG from '../../../assets/images/video-3.png';
import VIDEO4_IMG from '../../../assets/images/video-4.png';
import VIDEO5_IMG from '../../../assets/images/video-5.png';
import VIDEO6_IMG from '../../../assets/images/video-3.png';
import VIDEO7_IMG from '../../../assets/images/video-1.png';
import VIDEO8_IMG from '../../../assets/images/video-2.png';

export const data = [
  {
    img: VIDEO1_IMG,
    listen: '',
    buy: '',
    title: 'Heart of the trenches',
  },
  {
    img: VIDEO2_IMG,
    listen: '',
    buy: '',
    title: '#11 extended',
  },
  {
    img: VIDEO3_IMG,
    listen: '',
    buy: '',
    title: 'Heart of the trenches',
  },
  {
    img: VIDEO4_IMG,
    listen: '',
    buy: '',
    title: 'Heart of the trenches',
  },
  {
    img: VIDEO5_IMG,
    listen: '',
    buy: '',
    title: 'Heart of the trenches',
  },
  {
    img: VIDEO6_IMG,
    listen: '',
    buy: '',
    title: 'Heart of the trenches',
  },
  {
    img: VIDEO7_IMG,
    listen: '',
    buy: '',
    title: 'Heart of the trenches',
  },
  {
    img: VIDEO8_IMG,
    listen: '',
    buy: '',
    title: 'Heart of the trenches',
  },
];

export const youtube = [
  {
    title: 'Wrong Side',
    videoId: 'kw_PTOtwpEA',
    listen: 'https://www.youtube.com/watch?v=kw_PTOtwpEA',
    buy: '!#',
  },
  {
    title: 'Toxic Love',
    videoId: 'WxSSGfADquY',
    listen: 'https://www.youtube.com/watch?v=WxSSGfADquY',
    buy: '!#',
  },
  {
    title: 'Heartless',
    videoId: 'wgJob4LiF4M',
    listen: 'https://www.youtube.com/watch?v=wgJob4LiF4M',
    buy: '!#',
    isHd: true,
  },
  {
    title: 'Murder',
    videoId: '9_A9OBgWt5k',
    listen: 'https://www.youtube.com/watch?v=9_A9OBgWt5k',
    buy: '!#',
  },
  {
    title: 'Who We Are Ft. Melvoni',
    videoId: 'LvJiLD4V_wk',
    listen: 'https://www.youtube.com/watch?v=LvJiLD4V_wk',
    buy: '!#',
  },
  {
    title: 'Time Now',
    videoId: 'TKvghGE9TUw',
    listen: 'https://www.youtube.com/watch?v=TKvghGE9TUw',
    buy: '!#',
  },
  {
    title: '2020',
    videoId: 'd-y_ORVJ6gY',
    listen: 'https://www.youtube.com/watch?v=d-y_ORVJ6gY',
    buy: '!#',
  },
  {
    title: 'Too Long',
    videoId: '0yUSulTtDy8',
    listen: 'https://www.youtube.com/watch?v=0yUSulTtDy8',
    buy: '!#',
    isHd: true,
  },
];
