import { RoutePattern } from '../../../routes/RoutePattern';
import PhotosSlider from './PhotosSlider/PhotosSlider';
import { PhotosSliderDataTypes } from './PhotosSlider/types';
import PHOTO1_IMG from '../../../assets/images/photos/1.jpg';
import PHOTO2_IMG from '../../../assets/images/photos/2.jpg';
import PHOTO3_IMG from '../../../assets/images/photos/3.jpg';
import PHOTO4_IMG from '../../../assets/images/photos/4.jpg';
import PHOTO5_IMG from '../../../assets/images/photos/5.jpg';
import PHOTO6_IMG from '../../../assets/images/photos/6.jpg';
import PHOTO7_IMG from '../../../assets/images/photos/7.jpg';
import PHOTO8_IMG from '../../../assets/images/photos/8.jpg';
import PHOTO9_IMG from '../../../assets/images/photos/9.jpg';
import PHOTO10_IMG from '../../../assets/images/photos/10.jpg';

const data: PhotosSliderDataTypes[] = [
  {
    img: PHOTO1_IMG,
  },
  {
    img: PHOTO2_IMG,
  },
  {
    img: PHOTO3_IMG,
  },
  {
    img: PHOTO4_IMG,
  },
  {
    img: PHOTO5_IMG,
  },
  {
    img: PHOTO6_IMG,
  },
  {
    img: PHOTO7_IMG,
  },
  {
    img: PHOTO8_IMG,
  },
  {
    img: PHOTO9_IMG,
  },
  {
    img: PHOTO10_IMG,
  },
];

interface PhotosProps {}

const Photos = ({}: PhotosProps) => {
  return (
    <div id={RoutePattern.Photos}>
      <div className="md:min-h-screen">
        <PhotosSlider data={data} />
      </div>
    </div>
  );
};

export default Photos;
