import BGDESKTOP_IMG from '../../../assets/images/video-bg-desktop.png';
import GZSlider from '../../../components/GZSlider/GZSlider';
import { RoutePattern } from '../../../routes/RoutePattern';
import { youtube } from './data';
import VideoItem from './VideoItem';

export const Video = ({}) => {
  return (
    <div id={RoutePattern.Videos} className="px-5 py-16 md:py-24 relative overflow-hidden">
      <img
        src={BGDESKTOP_IMG}
        alt="bg"
        className="absolute top-0 left-0 h-full object-cover w-full z-0"
      />
      <h2 className="heading-2 uppercase from-bottom">video</h2>
      <div className="music-items grid-cols-1 justify-center items-center md:grid-cols-4 px-10 hidden md:grid">
        {youtube.map((item, idx) => (
          <VideoItem
            idx={idx}
            title={item.title}
            listen={item.listen}
            videoId={item.videoId}
            isHd={item.isHd}
            isAnimated
          />
        ))}
      </div>

      <GZSlider>
        {youtube.map((item, idx) => (
          <VideoItem
            idx={idx}
            title={item.title}
            listen={item.listen}
            videoId={item.videoId}
            isHd={item.isHd}
          />
        ))}
      </GZSlider>
    </div>
  );
};
