import { Bio } from './Bio/Bio';
import Hero from './Hero/Hero';
import { Music } from './Music/Music';
import Quote from './Quote/Quote';
import { Video } from './Video/Video';
import Events from './Events/Events';
import Photos from './Photos/Photos';

const Home = () => {
  return (
    <div id="home" className="w-full">
      <Hero />
      <Bio />
      <Photos />
      <Music />
      <Video />
      <Quote />
      <Events />
    </div>
  );
};

export default Home;
