import { RoutePattern } from '../../routes/RoutePattern';
import { MenuItemsTypes } from './types';

export const menuItems: MenuItemsTypes[] = [
  {
    name: 'home',
    to: RoutePattern.Home,
  },
  {
    name: 'bio',
    to: RoutePattern.Bio,
  },
  {
    name: 'photos',
    to: RoutePattern.Photos,
  },
  {
    name: 'music',
    to: RoutePattern.Music,
  },
  {
    name: 'videos',
    to: RoutePattern.Videos,
  },
  {
    name: 'events',
    to: RoutePattern.Events,
  },
];
