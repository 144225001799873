export default function RevealOnScroll() {
  const sr = (window as any).ScrollReveal();

  if (!sr) return;

  sr.reveal('.from-left', {
    duration: 1200,
    origin: 'left',
    distance: '30px',
    opacity: 0.2,
    reset: true,
    viewFactor: 0.2,
  });

  sr.reveal('.from-right', {
    duration: 1200,
    origin: 'right',
    distance: '30px',
    opacity: 0.2,
    reset: true,
    viewFactor: 0.2,
  });

  sr.reveal('.from-right-1200', {
    duration: 1200,
    origin: 'right',
    distance: '30px',
    opacity: 0.2,
    reset: true,
    viewFactor: 0.2,
  });

  sr.reveal('.from-bottom', {
    duration: 1200,
    scale: 1,
    origin: 'bottom',
    distance: '30px',
    reset: true,
    viewFactor: 0.2,
  });

  sr.reveal('.from-bottom-900', {
    duration: 1200,
    scale: 1,
    origin: 'bottom',
    distance: '30px',
    reset: true,
    viewFactor: 0.2,
  });

  sr.reveal('.from-top', {
    duration: 1200,
    scale: 1,
    origin: 'top',
    distance: '30px',
    reset: true,
    viewFactor: 0.2,
  });

  sr.reveal('.from-top-1200', {
    duration: 1200,
    scale: 1,
    origin: 'top',
    distance: '30px',
    reset: true,
    viewFactor: 0.2,
  });
}
