import { useEffect, useState } from 'react';
import GZSlider from '../../../components/GZSlider/GZSlider';
import { RoutePattern } from '../../../routes/RoutePattern';
import { data } from './data';
import MusicItems from './MusicItems';

export const Music = ({}) => {
  return (
    <div id={RoutePattern.Music} className="px-5 relative py-16 md:py-24">
      <h2 className="heading-2 uppercase z-10 from-bottom">music</h2>
      <div className="music-items flex-row flex-wrap justify-center items-center w-3/4 mx-auto px-10 hidden md:flex">
        {data.map((item, idx) => (
          <MusicItems
            idx={idx}
            title={item.title}
            listen={item.listen}
            img={item.img}
            buy={item.buy}
            isAnimated
          />
        ))}
      </div>

      <GZSlider>
        {data.map((item, idx) => (
          <MusicItems
            idx={idx}
            title={item.title}
            listen={item.listen}
            img={item.img}
            buy={item.buy}
          />
        ))}
      </GZSlider>
    </div>
  );
};
