import { faAngleLeft, faAngleRight, faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import BGDESKTOP_IMG from '../../../assets/images/about-bg-desktop.png';

const Container = styled.div`
  background-image: url(${BGDESKTOP_IMG});
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
`;

const Quote = () => {
  const renderNextArrow = (onClickHandler: any, hasNext: boolean, label: string) => (
    <button
      onClick={onClickHandler}
      className="text-white absolute right-5 bottom-5 z-50 outline-none focus:outline-none"
    >
      {hasNext && <FontAwesomeIcon icon={faAngleRight} size="2x" />}
    </button>
  );

  const renderPrevArrow = (onClickHandler: any, hasPrev: boolean, label: string) => (
    <button
      onClick={onClickHandler}
      className="text-white absolute left-5 bottom-5 z-50 outline-none focus:outline-none"
    >
      {hasPrev && <FontAwesomeIcon icon={faAngleLeft} size="2x" />}
    </button>
  );

  return (
    <Container className="relative overflow-hidden px-5 md:px-10 text-white py-24 md:py-40">
      <Carousel
        swipeable={false}
        emulateTouch
        showIndicators={false}
        showThumbs={false}
        showStatus={false}
        renderArrowNext={renderNextArrow}
        renderArrowPrev={renderPrevArrow}
        className="block overflow-visible"
      >
        <div className="from-bottom">
          <p className="text-white md:text-xl md:w-3/4 mx-auto text-center">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Totam asperiores non odit
            praesentium reiciendis, reprehenderit voluptates autem recusandae voluptate quia officia
            eligendi aliquam quos numquam inventore fugit corporis necessitatibus molestiae aut
            veniam vel quis! Molestias esse tenetur sit quisquam possimus in! Error veniam magni
            mollitia qui architecto beatae, ipsum quibusdam placeat tempore dolorum corporis
            aspernatur numquam, neque molestiae at distinctio officiis earum. Maiores ducimus
            officiis magni officia enim voluptatem dolores eligendi accusantium consequatur nisi?
            Voluptatem in, dignissimos commodi perferendis ea iste. Doloribus, nulla. Laudantium a,
            perspiciatis velit ducimus reiciendis sapiente quidem iusto aspernatur dolor ab libero
            quo, dolorem, qui recusandae.
          </p>

          <span className="text-center mt-10 block">
            <FontAwesomeIcon icon={faQuoteLeft} />
            <span className="ml-5">Lorem Ipsum</span>
          </span>
        </div>
        <div>
          <p className="text-white md:text-xl md:w-3/4 mx-auto text-center">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Totam asperiores non odit
            praesentium reiciendis, reprehenderit voluptates autem recusandae voluptate quia officia
            eligendi aliquam quos numquam inventore fugit corporis necessitatibus molestiae aut
            veniam vel quis! Molestias esse tenetur sit quisquam possimus in! Error veniam magni
            mollitia qui architecto beatae, ipsum quibusdam placeat tempore dolorum corporis
            aspernatur numquam, neque molestiae at distinctio officiis earum. Maiores ducimus
            officiis magni officia enim voluptatem dolores eligendi accusantium consequatur nisi?
            Voluptatem in, dignissimos commodi perferendis ea iste. Doloribus, nulla. Laudantium a,
            perspiciatis velit ducimus reiciendis sapiente quidem iusto aspernatur dolor ab libero
            quo, dolorem, qui recusandae.
          </p>

          <span className="text-center mt-10 block">
            <FontAwesomeIcon icon={faQuoteLeft} />
            <span className="ml-5">Lorem Ipsum</span>
          </span>
        </div>
      </Carousel>
    </Container>
  );
};

export default Quote;
