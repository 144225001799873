import { motion } from 'framer-motion';
import { useState } from 'react';
import { hover } from '../FramerMotion/Framer';
import { SlideUpSlideDown } from '../FramerMotion/Slide';
import { menuItems } from './data';
import { Link as ScrollLink } from 'react-scroll';

export default function DesktopNavigation() {
  const [crntHoveredItem, setCrntHoveredItem] = useState<number>(-1);

  const navItems = menuItems.map((item, idx) => (
    <li
      key={idx}
      onMouseOver={() => setCrntHoveredItem(idx)}
      onMouseOut={() => setCrntHoveredItem(-1)}
      className="text-white block text-xl uppercase cursor-pointer"
    >
      <ScrollLink smooth duration={1000} to={item.name} className="px-5 relative">
        {item.name}
        <motion.span
          variants={hover}
          animate={crntHoveredItem === idx ? 'visible' : 'hidden'}
          className="h-1 w-3/4 absolute -bottom-2 left-1/2 transform -translate-x-1/2 bg-white"
        />
      </ScrollLink>
    </li>
  ));

  return (
    <motion.ul
      {...SlideUpSlideDown}
      className="flex-row hidden w-full p-10 font-anton justify-center md:flex"
    >
      {navItems}
    </motion.ul>
  );
}
