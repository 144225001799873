import { HeroSliderDataTypes } from './HeroSlider/types';
import LOGO_IMG from '../../../assets/images/logo.png';
import { Socials } from '../../../components';
import HeroSlider from './HeroSlider/HeroSlider';
import styled from 'styled-components';
import HERO1_IMG from '../../../assets/images/bg-desktop.png';
import HERO1MOBILE_IMG from '../../../assets/images/bg-mobile.jpg';
import HERO2DESKTOP_IMG from '../../../assets/images/hero/hero1.jpg';
import HERO2MOBILE_IMG from '../../../assets/images/hero/hero1Mobile.jpg';
import HERO3DESKTOP_IMG from '../../../assets/images/hero/hero2.jpg';
import HERO3MOBILE_IMG from '../../../assets/images/hero/hero2Mobile.jpg';
import HERO4DESKTOP_IMG from '../../../assets/images/hero/hero3.jpg';
import HERO4MOBILE_IMG from '../../../assets/images/hero/hero3Mobile.jpg';
import HERO5DESKTOP_IMG from '../../../assets/images/hero/hero4.jpg';
import HERO5MOBILE_IMG from '../../../assets/images/hero/hero4Mobile.jpg';

const SocialContainer = styled.div`
  transform: translateY(-50%);
  top: 30%;

  @media (min-width: 768px) {
    margin-top: 0;
    top: 50%;
  }
`;

const Image = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const desktopImgs: HeroSliderDataTypes[] = [
  {
    img: HERO1_IMG,
  },
  {
    img: HERO2DESKTOP_IMG,
  },
  {
    img: HERO3DESKTOP_IMG,
  },
  {
    img: HERO4DESKTOP_IMG,
  },
  {
    img: HERO5DESKTOP_IMG,
  },
];

const mobileImgs = [
  {
    img: HERO1MOBILE_IMG,
  },
  {
    img: HERO2MOBILE_IMG,
  },
  {
    img: HERO3MOBILE_IMG,
  },
  {
    img: HERO4MOBILE_IMG,
  },
  {
    img: HERO5MOBILE_IMG,
  },
];

const Hero = () => {
  return (
    <div className="relative">
      <div className="min-h-screen from-right">
        <div className="block md:hidden">
          <HeroSlider data={mobileImgs} />
        </div>
        <div className="hidden md:block">
          <HeroSlider data={desktopImgs} />
        </div>
      </div>

      <SocialContainer className="absolute transform -translate-y-1/2 left-5 from-left">
        <Socials isActive={true} />
      </SocialContainer>

      <Image className="px-10 hidden md:block">
        <img src={LOGO_IMG} alt="" className="w-full from-bottom" />
      </Image>
    </div>
  );
};
export default Hero;
