import { Helmet } from 'react-helmet';

export default function IndexHelmet() {
  const title = 'Journey GZ';
  const description = 'JourneyGZ';
  const url = 'https://journey-gz.700brands.com';

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="user-scalable=no,initial-scale=1,maximum-scale=1,minimum-scale=1,width=device-width,height=device-height,target-densitydpi=device-dpi"
      />
      <meta name="theme-color" content="#000000" />
      <meta name="description" content={description} />

      {/* Website */}
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta name="apple-mobile-web-app-title" content={title} />

      {/* favicon */}
      <meta property="og:image" itemProp="image" content={`${url}/preview.png`} />
      <meta name="twitter:image:src" content={`${url}/preview.png`} />
      <meta itemProp="image" content={`${url}/preview.png`} />
      <meta name="image" content={`${url}/preview.png`} />
      <meta property="twitter_image" content={`${url}/preview.png`} />

      <link rel="icon" type="image/png" href={`${url}/Favicon.png`} />
      <link rel="apple-touch-icon" href={`${url}/FavIcon.png`} />
      <link rel="apple-touch-icon" sizes="76x76" href={`${url}/FavIcon.png`} />
      <link rel="apple-touch-icon" sizes="152x152" href={`${url}/FavIcon.png`} />
      <link rel="apple-touch-icon" sizes="167x167" href={`${url}/FavIcon.png`} />
      <link rel="apple-touch-icon" sizes="180x180" href={`${url}/FavIcon.png`} />
      <link rel="icon" sizes="192x192" href={`${url}/FavIcon.png`} />
      <meta name="msapplication-square310x310logo" content={`${url}/FavIcon.png`} />

      <link rel="manifest" href={`${url}/manifest.json`} />
      <title>{title}</title>
    </Helmet>
  );
}
