import { motion } from 'framer-motion';
import { useState } from 'react';
import { TransitionPowerThree } from '../FramerMotion/Transitions';
import Burger from './Burger';
import { menuItems } from './data';
import { Link as ScrollLink } from 'react-scroll';
import LOGO_IMG from '../../assets/images/logo.png';

const variant = {
  hidden: {
    left: '-100%',
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
  visible: {
    opacity: 1,
    left: '0',
    zIndex: 123123,
    transition: { ...TransitionPowerThree, duration: 1.2 },
  },
};

export default function MobileNavigation() {
  const [isBurgerActive, setIsBurgerActive] = useState(false);

  const renderMenuItems = menuItems.map((item, idx) => (
    <li
      key={idx}
      onClick={() => setIsBurgerActive(false)}
      className={`text-white block text-xl uppercase font-bold cursor-pointer ${
        !item.img && 'py-2'
      }`}
    >
      <ScrollLink
        smooth
        duration={1000}
        spy={true}
        to={item.name}
        onClick={() => setIsBurgerActive(false)}
      >
        {item.name}
      </ScrollLink>
    </li>
  ));

  return (
    <div className="flex py-2 px-5 items-center w-full bg-black md:w-auto md:hidden z-50">
      <div>
        <img src={LOGO_IMG} alt="logo" className="w-16" />
      </div>
      <div className="ml-auto" style={{ zIndex: 1231231231 }}>
        <Burger setIsBurgerActive={setIsBurgerActive} isBurgerActive={isBurgerActive} />
      </div>
      <motion.div
        variants={variant}
        animate={isBurgerActive ? 'visible' : 'hidden'}
        className="flex flex-col justify-center items-center fixed top-0 left-0 top-full text-white bg-black bg-opacity-90 w-full min-h-screen opacity-0"
      >
        <ul className="flex flex-col -mt-12">{renderMenuItems}</ul>
      </motion.div>
    </div>
  );
}
