interface VideoItemProps {
  idx: number;
  title: string;
  listen: string;
  videoId: string;
  isHd?: boolean;
  isAnimated?: boolean;
}

const VideoItem = ({ idx, title, listen, videoId, isHd, isAnimated = false }: VideoItemProps) => {
  const tabletWidth = window.innerWidth <= 768;

  return (
    <div key={idx} className="music-item mx-5">
      <img
        src={
          isHd
            ? `https://i3.ytimg.com/vi/${videoId}/hqdefault.jpg`
            : `https://i3.ytimg.com/vi/${videoId}/maxresdefault.jpg`
        }
        alt="music"
        className={`w-full h-full md:h-56 object-cover ${isAnimated && 'from-top'}`}
      />
      <div className="music-item-btns -mt-4">
        <button className="music-item-btn video-item-btn">
          <a href={listen} target="_blank" rel="noreferrer">
            watch now
          </a>
        </button>
      </div>
      <h4
        className={`text-white text-2xl uppercase font-bold text-center mt-3 ${
          isAnimated && 'from-bottom'
        }`}
      >
        {title}
      </h4>
    </div>
  );
};

export default VideoItem;
