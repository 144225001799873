import MUSIC1_IMG from '../../../assets/images/music-1.png';
import MUSIC2_IMG from '../../../assets/images/music-2.png';
import MUSIC3_IMG from '../../../assets/images/music-3.png';
import MUSIC4_IMG from '../../../assets/images/music-4.png';
import AMAZON_IMG from '../../../assets/images/logo_amazon_music.svg';
import APPLE_IMG from '../../../assets/images/logo_apple.svg';
import DEEZER_IMG from '../../../assets/images/logo_deezer.svg';
import NAPSTER_IMG from '../../../assets/images/logo_napster.svg';
import PANDORA_IMG from '../../../assets/images/logo_pandora.svg';
import SOUNDCLOUD_IMG from '../../../assets/images/logo_soundcloud.svg';
import SPOTIFY_IMG from '../../../assets/images/logo_spotify.svg';
import ITUNES_IMG from '../../../assets/images/logo_itunes.svg';
import BEATPORT_IMG from '../../../assets/images/logo_beatport.svg';
import GOOGLE_PLAY_IMG from '../../../assets/images/logo_google_play.svg';

export const data = [
  {
    img: MUSIC1_IMG,
    listen: '!#',
    buy: '!#',
    title: 'Heart of the trenches',
  },
  {
    img: MUSIC2_IMG,
    listen: '!#',
    buy: '!#',
    title: '#11 extended',
  },
  {
    img: MUSIC3_IMG,
    listen: '!#',
    buy: '!#',
    title: '11',
  },
  {
    img: MUSIC4_IMG,
    listen: '!#',
    buy: '!#',
    title: 'better days',
  },
];

export const streamBtns = [
  {
    link: '!#',
    img: AMAZON_IMG,
  },
  {
    link: '!#',
    img: APPLE_IMG,
  },
  {
    link: '!#',
    img: DEEZER_IMG,
  },
  {
    link: '!#',
    img: NAPSTER_IMG,
  },
  {
    link: '!#',
    img: PANDORA_IMG,
  },
  {
    link: '!#',
    img: SOUNDCLOUD_IMG,
  },
  {
    link: '!#',
    img: SPOTIFY_IMG,
  },
];

export const buyBtns = [
  {
    link: '!#',
    img: AMAZON_IMG,
  },
  {
    link: '!#',
    img: ITUNES_IMG,
  },
  {
    link: '!#',
    img: BEATPORT_IMG,
  },
  {
    link: '!#',
    img: GOOGLE_PLAY_IMG,
  },
];
