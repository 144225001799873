import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion, Variants } from 'framer-motion';
import { data } from './SocialsData';

const variant: Variants = {
  hidden: {
    opacity: 0,
    y: -30,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

interface SocialsProps {
  isActive?: boolean;
}

export default function Socials({ isActive }: SocialsProps) {
  const socialItems = data.map((item, idx) => (
    <li
      key={idx}
      className="text-white w-10 h-10 text-center flex justify-center items-center hover:text-gray-300 transition-all"
    >
      <a href={item.to} rel="noreferrer" target="_blank">
        {!item.icon ? (
          <img src={item.img} alt="img" style={{ filter: 'invert(100%)' }} />
        ) : (
          <FontAwesomeIcon icon={item.icon} />
        )}
      </a>
    </li>
  ));

  return (
    <motion.section
      initial="hidden"
      variants={variant}
      animate={isActive ? 'visible' : 'hidden'}
      id="socials"
      className="py-3"
    >
      <ul className="text-black flex flex-col justify-center items-center text-2xl">
        {socialItems}
      </ul>
    </motion.section>
  );
}
