import { motion } from 'framer-motion';
import { useState } from 'react';
import { slideDownVariant } from '../../../components/FramerMotion/Framer';
import { buyBtns, streamBtns } from './data';

export interface MusicItemsProps {
  idx: number;
  img: string;
  title: string;
  listen: string;
  buy: string;
  isAnimated?: boolean;
}

const MusicItems: React.FC<MusicItemsProps> = ({
  idx,
  buy,
  img,
  title,
  listen,
  isAnimated = false,
}: MusicItemsProps) => {
  const [crntActiveListen, setCrntActiveListen] = useState<boolean>(false);
  const [isBuyActive, setIsBuyActive] = useState<boolean>(false);
  const isListenDropdownActive = crntActiveListen;
  const isBuyDropdownActive = isBuyActive;

  function handleListeNowClick() {
    setCrntActiveListen(!crntActiveListen);
    setIsBuyActive(false);
  }

  function handleBuyNowClick() {
    setIsBuyActive(!isBuyDropdownActive);
    setCrntActiveListen(false);
  }

  function handleImageClicked() {
    setIsBuyActive(false);
    setCrntActiveListen(false);
  }

  console.log(isBuyDropdownActive);

  return (
    <div key={idx} className="music-item w-1/2 md:p-5 relative">
      <div className="relative">
        <div
          onClick={handleImageClicked}
          onTouchStart={handleImageClicked}
          onTouchEnd={handleImageClicked}
        >
          <img src={img} alt="music" className={`w-full ${isAnimated && 'from-top'}`} />
        </div>
        <div className="music-item-btns">
          <div className="relative">
            <button onClick={handleListeNowClick} className="music-item-btn">
              listen now
            </button>
            <motion.ul
              initial="hidden"
              variants={slideDownVariant}
              animate={isListenDropdownActive ? 'visible' : 'hidden'}
              className="border-b border-white border-t-0 border-r border-l absolute top-full left-0 bg-white w-full z-50"
              style={{ zIndex: crntActiveListen ? '123123213' : '-1231' }}
            >
              {streamBtns.map((item, idx) => (
                <li
                  key={idx}
                  className="p-3"
                  style={{ visibility: isListenDropdownActive ? 'visible' : 'hidden' }}
                >
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <img
                      src={item.img}
                      alt={item.link}
                      className="h-8 mx-auto"
                      width={isListenDropdownActive ? 'auto' : 0}
                    />
                  </a>
                </li>
              ))}
            </motion.ul>
          </div>
          <div className="relative mt-3">
            <button onClick={handleBuyNowClick} className="music-item-btn">
              buy now
            </button>
            <motion.ul
              initial="hidden"
              variants={slideDownVariant}
              animate={isBuyDropdownActive ? 'visible' : 'hidden'}
              className="border-b border-white border-t-0 border-r border-l absolute top-full left-0 bg-white w-full z-50"
              style={{ zIndex: isBuyDropdownActive ? '123123213' : '-1231' }}
            >
              {buyBtns.map((item, idx) => (
                <li
                  key={idx}
                  className="p-3"
                  style={{ visibility: isBuyDropdownActive ? 'visible' : 'hidden' }}
                >
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <img
                      src={item.img}
                      alt={item.link}
                      className="h-8 mx-auto"
                      width={isBuyDropdownActive ? 'auto' : 0}
                    />
                  </a>
                </li>
              ))}
            </motion.ul>
          </div>
        </div>
      </div>
      <div
        className={`text-white text-2xl uppercase font-bold text-center mt-3  ${
          isAnimated && 'from-bottom'
        }`}
      >
        <h4>{title}</h4>
      </div>
    </div>
  );
};

export default MusicItems;
