import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Carousel } from 'react-responsive-carousel';
import GZSlider from '../../../components/GZSlider/GZSlider';
import { RoutePattern } from '../../../routes/RoutePattern';

const data = [
  {
    title: 'lorem ipsuum dolor sit amet.',
    date: '10/13/21',
    description: 'Lorem ipsum',
    tickets: '!#',
  },
  {
    title: 'lorem ipsuum dolor sit amet.',
    date: '10/13/21',
    description: 'Lorem ipsum',
    tickets: '!#',
  },
  {
    title: 'lorem ipsuum dolor sit amet.',
    date: '10/13/21',
    description: 'Lorem ipsum',
    tickets: '!#',
  },
];

const Events = () => {
  return (
    <div id={RoutePattern.Events} className="px-10 relative py-16 bg-black md:p-24 from-bottom">
      <h2 className="heading-2 uppercase from-bottom">events</h2>
      <div className="hidden flex-col justify-center items-center md:flex">
        {data.map((item, idx) => (
          <div className="mt-5 flex flex-col justify-center items-center text-white text-center md:flex-row">
            <h4 className="text-red-500 text-2xl uppercase font-bold md:mx-5 from-right">
              {item.title}
            </h4>
            <span className="uppercase text-2xl md:mx-5 from-left">{item.date}</span>
            <p className="text-xl mt-4 md:mt-0 from-bottom">{item.description}</p>
            <button className=" music-item-btn mx-auto my-4 md:my-0 md:mx-5 from-top">
              <a href={item.tickets} target="_blank" rel="noreferrer">
                buy tickets
              </a>
            </button>
          </div>
        ))}
      </div>

      <GZSlider>
        {data.map((item, idx) => (
          <div className="mt-5 flex flex-col text-white text-center md:flex-row">
            <h4 className="text-red-500 text-2xl uppercase font-bold">{item.title}</h4>
            <span className="uppercase text-2xl">{item.date}</span>
            <p className="text-xl mt-4">{item.description}</p>
            <button className="mx-auto my-4 music-item-btn">
              <a href={item.tickets} target="_blank" rel="noreferrer">
                buy tickets
              </a>
            </button>
          </div>
        ))}
      </GZSlider>
    </div>
  );
};

export default Events;
