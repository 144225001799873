import { IndexHelmet } from './components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import Navigation from './components/Navigation/Navigation';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import Footer from './components/Footer/Footer';
import './components/FontLoader/FontLoader';
import { useEffect } from 'react';
import Libs from './Libs';
import Preloader from './components/Preloader/Preloader';

const App = () => {
  useEffect(() => {
    Libs();
  }, []);

  return (
    <Router>
      <IndexHelmet />
      <Navigation />
      <Switch>
        <Route path="*" component={AppRoutes} />
      </Switch>
      <Footer />
    </Router>
  );
};
export default App;
