import BG_IMG from '../../../assets/images/bio-bg-desktop.png';
import { RoutePattern } from '../../../routes/RoutePattern';

export const Bio = ({}) => {
  return (
    <div
      id={RoutePattern.Bio}
      className="text-white py-24 md:py-40 px-5 relative md:px-10 overflow-hidden"
    >
      <img
        src={BG_IMG}
        alt="bg"
        className="fixed top-0 left-0 h-full w-full object-cover -z-10 from-top"
      />
      <h2 className="heading-2 from-bottom">BIO</h2>
      <div>
        <p className="w-full md:w-3/4 mx-auto from-left">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut doloremque deserunt velit
          expedita itaque? Velit cumque totam non nihil ipsam. Beatae cumque alias esse est
          perferendis, itaque molestias minus velit impedit error soluta provident similique dolores
          nisi dolorem doloremque quia quisquam perspiciatis eaque possimus aliquid suscipit ducimus
          voluptas. Sequi, magnam?
        </p>
        <br />
        <p className="w-full md:w-3/4 mx-auto from-left">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut doloremque deserunt velit
          expedita itaque? Velit cumque totam non nihil ipsam. Beatae cumque alias esse est
          perferendis, itaque molestias minus velit impedit error soluta provident similique dolores
          nisi dolorem doloremque quia quisquam perspiciatis eaque possimus aliquid suscipit ducimus
          voluptas. Sequi, magnam?
        </p>
      </div>
    </div>
  );
};
