import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faApple,
  faFacebookF,
  faInstagram,
  faSoundcloud,
  faSpotify,
  faTiktok,
  faTwitter,
  faYoutube,
  IconDefinition,
} from '@fortawesome/free-brands-svg-icons';
import TRILLERLOGO_IMG from '../../assets/images/triller-logo.png';

// temporary types (need to be debugged)
export interface SocialDataTypes {
  icon?: IconProp;
  to: string;
  img?: string;
}

export const data: SocialDataTypes[] = [
  {
    icon: faYoutube,
    to: 'https://www.youtube.com/channel/UCZpxnTQtCSwcQ3D5WrjFqYg',
  },
  {
    icon: faInstagram,
    to: 'https://www.instagram.com/journey.gz/',
  },
  {
    icon: faTiktok,
    to: '#',
  },
  {
    icon: faTwitter,
    to: 'https://twitter.com/journey_gz',
  },
  {
    img: TRILLERLOGO_IMG,
    to: 'https://www.facebook.com/JourneyGzMusic',
  },
  {
    icon: faFacebookF,
    to: 'https://www.facebook.com/JourneyGzMusic',
  },
];
